.d1 {
  background: linear-gradient(180deg, #ffffff 50%, #131313 50%);
  padding: 10px !important;
  margin: 0px !important;
}

.left,
.right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.pill1 {
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pillAlign {
  display: flex;
  justify-content: center;
  width: 100;
}

.pill2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cen {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.pill3 {
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pill4 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
